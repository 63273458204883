import React, {
    useEffect,
    useState,
    Fragment,
    useMemo,
} from 'react';
import { useRecoilValue, useRecoilState } from 'recoil';
import {
    Dropdown,
    Button,
    Icon,
    Spinner,
    Tree,
} from 'metroplex';
import { impersonationMenu } from 'core/framework/recoil/translations';
import impersonationAtom from 'core/framework/recoil/atoms/impersonation-atom';
import icons from 'core/icons';
import hierarchyAtom from 'core/framework/recoil/atoms/hierarchy-atom';
import isMobileAtom from 'core/framework/recoil/atoms/is-mobile-atom';

import './ImpersonationMenu.scss';

/**
 * The ImpersonationMenu component renders a dropdown impersonation menu for a user to switch to different levels.
 *
 * @returns {JSX.Element} The rendered ImpersonationMenu component.
 */
const ImpersonationMenu = () => {
    const translations = useRecoilValue(impersonationMenu);
    const [impersonation, setImpersonation] = useRecoilState(impersonationAtom);
    const hierarchy = useRecoilValue(hierarchyAtom);
    const [loading, setLoading] = useState(false);
    const isMobile = useRecoilValue(isMobileAtom);

    const impersonationScopeUniqid = useMemo(() => (
        impersonation.selectedScope?.uniqid
        || impersonation.userScope?.uniqid
    ), [impersonation]);

    const impersonationScopeLabel = useMemo(() => {
        const findLabel = items => {
            let label = null;

            items.forEach(item => {
                if (item.uniqid === impersonationScopeUniqid) {
                    label = item.label;
                } else if (item.children) {
                    const childLabel = findLabel(item.children);

                    if (childLabel) {
                        label = childLabel;
                    }
                }
            });

            return label;
        };

        return findLabel([hierarchy]);
    }, [impersonation, hierarchy]);

    useEffect(() => {
        setLoading(!hierarchy);
    }, [hierarchy]);

    /**
     * Changes the impersonation level based on the provided unique identifier.
     * Determines the type of impersonation (venue, company, group) from the unique identifier prefix.
     * Updates the impersonation state with the new scope type and unique identifier.
     *
     * @param {string} scopeType - The type of the impersonation level.
     * @param {string} scopeUniqid - The unique identifier for the impersonation level.
     */
    const changeImpersonationLevel = ({ type, uniqid }) => {
        if (!uniqid || uniqid === impersonationScopeUniqid) {
            return;
        }

        if (type) {
            setImpersonation(prevState => ({
                ...prevState,
                selectedScope: { type, uniqid },
            }));
        }
    };

    /**
     * The hierarchy of open nodes based on the current impersonation scope.
     * This function identifies the parent nodes of the current impersonation scope and updates the state
     * to reflect the open hierarchy, ensuring the correct nodes are expanded in the UI.
     */
    const openHierarchy = useMemo(() => {
        const openNode = impersonationScopeUniqid;
        const parentIds = [openNode];

        /**
         * Recursively finds and collects the parent IDs of a target node within a hierarchy.
         *
         * @param {Array} nodes - The array of nodes to search through.
         * @param {string} targetId - The unique identifier of the target node.
         * @returns {boolean} - Returns true if the target node is found, otherwise false.
         */
        const findParentIds = (nodes, targetId) => {
            let found = false;
            nodes.forEach(node => {
                if (node.uniqid === targetId) {
                    found = true;
                } else if (node.children && findParentIds(node.children, targetId)) {
                    parentIds.unshift(node.uniqid);
                    found = true;
                }
            });

            return found;
        };

        findParentIds([hierarchy], openNode);
        return parentIds;
    }, [impersonation.selectedScope, hierarchy]);

    const getHierarchyIcon = (node, hasChildren, isOpen) => {
        if (hasChildren) {
            if (node.type === 'company') {
                return <Icon icon={icons.scopeCustomer} />;
            }

            return isOpen
                ? <Icon classNames="open" icon={icons.scopeGroupOpen} />
                : <Icon classNames="closed" icon={icons.scopeGroup} />;
        }

        return <Icon icon={icons.scopeVenue} />;
    };

    return (
        <div>
            <Dropdown
                dropRight
                classNames="Dropdown--impersonation-menu"
                button={(
                    <Button classNames="CustomerName" topbar>
                        <Icon icon={icons.customer} />
                        { !isMobile && (
                            <Fragment>
                                <span className="ImpersonationLabel">{impersonationScopeLabel} </span>
                                <span className="caret"><Icon icon={icons.dropdownCaret} /></span>
                            </Fragment>
                        )}
                    </Button>
                )}
                render={closeDropdown => (
                    !loading ? (
                        <Fragment>
                            <span>{translations.setScope}</span>
                            <div className="ImpersonationList">
                                <Tree
                                    nodes={[hierarchy]}
                                    openNodes={openHierarchy}
                                    idAttribute="uniqid"
                                    renderNode={(node, hasChildren, toggleMenu, isOpen, classes) => (
                                        <div id={node.uniqid} className={`${classes} ${impersonationScopeUniqid === node?.uniqid ? 'ImpersonationScope' : ''} EstateItem`}>
                                            <div className="EstateLabel" onClick={node.type !== 'company' ? toggleMenu : undefined}>
                                                {getHierarchyIcon(node, hasChildren, isOpen)}
                                                <p>{node.label}</p>
                                            </div>
                                            <Button
                                                className="ImpersonationButton"
                                                onClick={() => {
                                                    changeImpersonationLevel(node);
                                                    closeDropdown();
                                                }}
                                            >
                                                <Icon icon={icons.impersonationEye} />
                                            </Button>
                                        </div>
                                    )}
                                />
                            </div>
                            <div className="Dropdown__footer">
                                <span>{translations.currentScope} <strong>{impersonationScopeLabel}</strong></span>
                            </div>
                        </Fragment>
                    ) : <div className="AccountMenu__dropdown--loading"><Spinner modifier="spinner--large" /></div>
                )}
            />
        </div>
    );
};

export default ImpersonationMenu;
