/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React from 'react';

const PurpleLogoSVG = ({ primaryColour = '#011638', secondaryColour = '#7458fd' }) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1045 274.97">
        <path fill={primaryColour} d="M448.65,168.73v-89.46h38.7v89.46c0,18.66,10.98,31.29,29.92,31.29s29.92-12.63,29.92-31.29v-89.46h38.7v89.46c0,42.54-26.35,66.69-68.62,66.69s-68.61-24.43-68.61-66.69Z" />
        <path fill={primaryColour} d="M848.72,231.85V56.4h38.7v175.45h-38.7Z" />
        <path fill={primaryColour} d="M448.57,151.24c-1.87-40.01-34.1-72.61-74.08-74.92-17.28-1-33.44,3.61-46.87,12.17v-9.22h-38.42v177.18h38.7v-34.76c12.15,7.68,26.54,12.13,41.95,12.13,44.71,0,80.84-37.42,78.73-82.58ZM369.84,197.09c-23.21,0-42.09-18.88-42.09-42.09s18.88-42.09,42.09-42.09,42.09,18.88,42.09,42.09-18.88,42.09-42.09,42.09Z" />
        <path fill={primaryColour} d="M848.65,151.24c-1.87-40.01-34.1-72.61-74.08-74.92-17.28-1-33.44,3.61-46.87,12.17v-9.22h-38.42v177.18h38.7v-34.76c12.15,7.68,26.54,12.13,41.95,12.13,44.71,0,80.84-37.42,78.73-82.58ZM769.92,197.09c-23.21,0-42.09-18.88-42.09-42.09s18.88-42.09,42.09-42.09,42.09,18.88,42.09,42.09-18.88,42.09-42.09,42.09Z" />
        <path fill={primaryColour} d="M633.58,158.01c0-23.21,18.88-42.09,42.09-42.09,1.52,0,3.02.09,4.5.24v-36.84c-17.22-.96-33.33,3.64-46.72,12.17v-12.23h-38.42v152.59h38.7l-.14-73.84Z" />
        <g>
            <g>
                <path fill={primaryColour} d="M129.7,165.85c-59.99,0-110.94-39.48-128.22-93.83C-4.93,51.88,10.28,31.34,31.42,31.34h0c13.72,0,25.7,8.97,29.92,22.02,9.32,28.86,36.44,49.8,68.37,49.8,39.6,0,71.82-32.22,71.82-71.82h62.69c0,74.17-60.34,134.51-134.51,134.51Z" />
                <circle fill={secondaryColour} cx="232.87" cy="31.34" r="31.34" />
            </g>
            <g>
                <path fill={primaryColour} d="M197.43,255.6h0c-8.56,0-16.23-5.58-18.55-13.81-6.04-21.44-25.77-37.2-49.11-37.2-28.13,0-51.01,22.88-51.01,51.01h-38.74c0-49.49,40.26-89.76,89.76-89.76,40.88,0,75.47,27.47,86.26,64.92,3.58,12.42-5.67,24.83-18.6,24.83Z" />
                <circle fill={secondaryColour} cx="59.38" cy="255.6" r="19.37" />
            </g>
        </g>
        <path fill={primaryColour} d="M1008.26,156.51c-.35,10.28-4.43,20.46-12.26,28.29-13.13,13.13-32.83,15.74-48.59,7.87l56.46-56.46,26.69-26.69c-2.54-3.58-5.39-7-8.59-10.2-3.69-3.69-7.65-6.92-11.82-9.72-30.64-20.6-72.55-17.37-99.64,9.72-30.78,30.78-30.78,80.68,0,111.46,30.78,30.78,80.68,30.78,111.46,0,15.02-15.02,22.67-34.58,23.03-54.26h-36.74ZM936.47,125.29c12.61-12.61,31.3-15.52,46.73-8.75l-55.47,55.47c-6.77-15.43-3.87-34.11,8.74-46.73Z" />
    </svg>
);

export default PurpleLogoSVG;
