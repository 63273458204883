import React, { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { Icon, Button } from 'metroplex';
import { useRecoilValue } from 'recoil';
import { generic } from 'core/framework/recoil/translations';
import isMobileAtom from 'core/framework/recoil/atoms/is-mobile-atom';
import brandingAtom from 'core/framework/recoil/atoms/branding-atom';
import ImpersonationMenu from 'core/framework/components/ImpersonationMenu/ImpersonationMenu';
import AccountMenu from 'core/framework/components/Topbar/AccountMenu/AccountMenu';
import icons from 'core/icons';
import NavigationService from 'core/framework/services/navigation-service';
import AuthenticationService from 'core/framework/services/authentication-service';
import AppsMenu from 'core/framework/components/Topbar/AppsMenu/AppsMenu';
import PurpleLogoSVG from 'core/framework/components/Topbar/PurpleLogoSVG';

import './Topbar.scss';

const Topbar = () => {
    const genericTranslations = useRecoilValue(generic);
    const [appSelectorData, setAppSelectorData] = useState([]);
    const isAuthenticated = useMemo(() => AuthenticationService.isAuthenticated(), []);
    const [launchpadLink, setLaunchpadLink] = useState([]);
    const [supportLink, setSupportLink] = useState([]);
    const isMobile = useRecoilValue(isMobileAtom);
    const branding = useRecoilValue(brandingAtom);

    useEffect(() => {
        NavigationService.getCustomerApps(
            response => {
                setAppSelectorData(response);

                const launchpad = response.find(item => item.title === 'Launchpad');
                setLaunchpadLink(launchpad.link);

                const support = response.find(item => item.title === 'Support');
                setSupportLink(support.link);
            },
            () => {},
        );
    }, [isAuthenticated]);

    const accountMenuList = useMemo(() => {
        const list = [];

        if (launchpadLink) {
            list.push({
                label: 'Your profile',
                icon: icons.profile,
                onClick: () => window.open(`${launchpadLink}/your-profile`, '_blank'),
            });
        }

        if (supportLink) {
            list.push({
                label: 'Help and support',
                icon: icons.help,
                actionIcon: icons.externalLink,
                onClick: () => window.open(supportLink, '_blank'),
            });
        }

        return list;
    }, [appSelectorData]);

    return (
        <aside className="Topbar">
            <div className="TopbarSection TopbarSection--left">
                <a href={launchpadLink ?? ''} rel="noreferrer">
                    <Button>
                        <Icon icon={icons.oneDirection} style={{ transform: 'rotate(180deg)' }} />
                        {!isMobile && (
                            <span>{genericTranslations.goBack}</span>
                        )}
                    </Button>
                </a>
            </div>
            <div className="TopbarSection TopbarSection--center">
                <Link to="/dashboard">
                    <div className="logo">
                        {branding.isMainWhitelabel && (
                            <PurpleLogoSVG primaryColour="#fff" />
                        )}
                        <span className="product-name">{branding.isMainWhitelabel ? 'analytics' : 'Analytics'}</span>
                        <span className="beta">BETA</span>
                    </div>
                </Link>
            </div>
            <div className="TopbarSection TopbarSection--right">
                <div>
                    <AppsMenu apps={appSelectorData} />
                </div>
                <div>
                    <AccountMenu menu={accountMenuList} />
                </div>
                <div>
                    <ImpersonationMenu />
                </div>
            </div>
        </aside>
    );
};

export default Topbar;
