import React, {
    useMemo, Fragment, useEffect, useState,
} from 'react';
import {
    useRecoilState, useSetRecoilState, useRecoilValue,
} from 'recoil';
import PropTypes from 'prop-types';
import {
    Dropdown, Button, Icon, Spinner,
} from 'metroplex';
import { useNavigate } from 'react-router-dom';
import { accountMenu } from 'core/framework/recoil/translations';
import icons from 'core/icons';
import { alert } from 'core/framework/recoil/page';
import accountInfoAtom from 'core/framework/recoil/atoms/account-info-atom';
import AuthenticationService from 'core/framework/services/authentication-service';

import './AccountMenu.scss';

const AccountMenu = ({ menu }) => {
    const translations = useRecoilValue(accountMenu);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [account, updateAccount] = useRecoilState(accountInfoAtom);
    const isAuthenticated = useMemo(() => AuthenticationService.isAuthenticated(), []);
    const setAlert = useSetRecoilState(alert);

    useEffect(() => {
        if (isAuthenticated) {
            AuthenticationService.getUserDetails(
                response => {
                    updateAccount(response);
                    setLoading(false);
                },
                error => setAlert({ show: true, message: error.message, type: 'error' }),
            );
        }
    }, [isAuthenticated]);

    const userInitials = useMemo(() => (
        account.firstname && account.lastname
            ? `${account.firstname.charAt().toUpperCase()}${account.lastname.charAt().toUpperCase()}`
            : null
    ), [account]);

    return (
        <Dropdown
            dropRight
            classNames="AccountMenu Dropdown--topbar"
            button={(
                <Button topbar icon>
                    <Icon classNames="AccountMenuButton" icon={icons.user} />
                </Button>
            )}
            render={closeDropdown => (
                (!loading && account)
                    ? (
                        <Fragment>
                            <div className="account-profile">
                                <div className="account-profile__avatar">{userInitials}</div>
                                <div className="account-profile__info">
                                    <p className="account-profile__name">
                                        {account.firstname && `${account.firstname} ${account.lastname}`}
                                    </p>
                                    <p className="account-profile__email">{account.username}</p>
                                </div>
                            </div>
                            <ul className="AccountMenu__links">
                                {menu.map(item => (
                                    <li>
                                        <Button onClick={item.onClick}>
                                            <Icon icon={item.icon} />
                                            {item.label}
                                            {item.actionIcon && <Icon classNames="ActionIcon" icon={item.actionIcon} />}
                                        </Button>
                                    </li>
                                ))}
                            </ul>
                            <div className="Dropdown__footer">
                                <Button
                                    flexRight
                                    size="small"
                                    color="primary"
                                    onClick={() => {
                                        closeDropdown();
                                        navigate('/logout');
                                    }}
                                    closeDropdown={closeDropdown}
                                >
                                    {translations.logout}
                                </Button>
                            </div>
                        </Fragment>
                    )
                    : <div className="Topbar__dropdown--loading"><Spinner modifier="spinner--large" /></div>
            )}
        />
    );
};

AccountMenu.propTypes = {
    menu: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
};

export default AccountMenu;
