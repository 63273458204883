import ENV from 'core/utils/env-helper';
import Request from 'core/utils/request';

const LevelService = {
    getLevelsApiBaseUrl() {
        const currentHost = window.location.origin;

        return ENV.get().REACT_APP_LEVELS_API_URL || `${currentHost}/services/levels`;
    },

    getLevelsApiUrl(path) {
        return `${this.getLevelsApiBaseUrl()}${path}`;
    },

    getUserScope(successCallback, errorCallback) {
        Request.get(this.getLevelsApiUrl('/v1/entities/user/level-type'))
            .then(response => successCallback(response))
            .catch(error => errorCallback(error));
    },

    getImpersonationHierarchy({ type, uniqid }, successCallback, errorCallback) {
        Request.get(this.getLevelsApiUrl(`/v1/entities/${type}/${uniqid}/descendants`))
            .then(response => successCallback(response))
            .catch(error => errorCallback(error));
    },

    getVenueIds(uniqids, successCallback, errorCallback) {
        Request.post(this.getLevelsApiUrl('/v1/entities/venue/ids'), { uniqids })
            .then(response => successCallback(response))
            .catch(error => errorCallback(error));
    },

    getVenues(nodes, expectedUniqid, venueIds = [], estateFound = false) {
        nodes.forEach(node => {
            if ((estateFound || node.uniqid === expectedUniqid) && node.type === 'venue') {
                venueIds.push(node.uniqid);
            }

            if (node.children?.length > 0) {
                this.getVenues(
                    node.children,
                    expectedUniqid,
                    venueIds,
                    estateFound || node.uniqid === expectedUniqid,
                );
            }
        });

        return venueIds;
    },
};

export default LevelService;
