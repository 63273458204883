/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useRecoilValue } from 'recoil';
import {
    Dropdown,
    Button,
    Icon,
    BlankFeature,
} from 'metroplex';
import { appsMenu } from 'core/framework/recoil/translations';
import IconCoin from 'core/framework/components/Topbar/AppsMenu/IconCoin/IconCoin';
import icons from 'core/icons';
import brandingAtom from 'core/framework/recoil/atoms/branding-atom';

import './AppsMenu.scss';

const purpleIconLogo = (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 1008.9 1050">
        <g>
            <path fill="#00163a" d="M495.27,633.31c-229.09,0-423.64-150.77-489.62-358.29C-18.81,198.1,39.25,119.69,119.97,119.69h0c52.39,0,98.14,34.24,114.24,84.1,35.59,110.21,139.17,190.15,261.07,190.15,151.22,0,274.26-123.03,274.26-274.25h239.37c0,283.21-230.41,513.62-513.63,513.62Z" />
            <circle fill="#7951ff" cx="889.21" cy="119.69" r="119.69" />
        </g>
        <g>
            <path fill="#00163a" d="M753.92,976.03h0c-32.68,0-61.98-21.3-70.84-52.75-23.06-81.87-98.4-142.05-187.55-142.05-107.41,0-194.8,87.39-194.8,194.8h-147.94c0-188.99,153.75-342.75,342.74-342.75,156.1,0,288.17,104.9,329.4,247.92,13.68,47.44-21.65,94.83-71.02,94.83Z" />
            <circle fill="#7951ff" cx="226.76" cy="976.03" r="73.97" />
        </g>
    </svg>
);

const AppsMenu = ({ apps }) => {
    const translations = useRecoilValue(appsMenu);
    const branding = useRecoilValue(brandingAtom);

    const appList = data => data.map(item => {
        const buttonClasses = classnames(
            'AppsMenu__button',
            { 'AppsMenu__button--active': item.icon === 'microSurvey' },
        );

        return (
            <a href={item.link} target="_blank" rel="noreferrer" key={item.title}>
                <Button classNames={buttonClasses}>
                    {item.icon === 'rocket'
                        ? purpleIconLogo
                        : (
                            <IconCoin
                                icon={icons[item.icon]}
                                color={item.colour}
                            />
                        )
                    }
                    <span>{branding.isMainWhitelabel ? 'Purple ' : ''}{item.title}</span>
                </Button>
            </a>
        );
    });

    return (
        <Dropdown
            dropRight
            classNames="Dropdown--topbar"
            button={(
                <Button topbar icon>
                    <Icon icon={icons.apps} />
                </Button>
            )}
            render={() => (
                <div className="AppsMenu">
                    <span
                        className="AppsMenu__title"
                        style={{
                            textTransform: branding.isMainWhitelabel ? 'none' : 'capitalize',
                        }}
                    >
                        {branding.isMainWhitelabel ? 'Purple ' : ''}{translations.apps}
                    </span>
                    {apps.length
                        ? appList(apps)
                        : (
                            <BlankFeature title={translations.noApplications} />
                        )}
                </div>
            )}
        />
    );
};

AppsMenu.propTypes = {
    apps: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
};

export default AppsMenu;
