import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Icon } from 'metroplex';

import './IconCoin.scss';

const IconCoin = ({
    classNames,
    icon,
    size,
    color,
}) => {
    const parentClass = classnames(
        'IconCoin',
        { [`icon-coin--${size}`]: size !== 'm' },
        classNames,
    );

    const parentStyle = { color };

    return (
        <div className={parentClass} style={parentStyle}>
            <Icon icon={icon} />
        </div>
    );
};

IconCoin.propTypes = {
    classNames: PropTypes.string,
    icon: PropTypes.string,
    size: PropTypes.oneOf(['s', 'm', 'l']),
    color: PropTypes.string,
};

IconCoin.defaultProps = {
    classNames: undefined,
    icon: undefined,
    size: 'm',
    color: undefined,
};

export default IconCoin;
