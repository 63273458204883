/* eslint-disable import/no-unresolved */
import { useEffect, useState } from 'react';
import { values, mergeLeft, pick } from 'ramda';
import { useRecoilValue } from 'recoil';
import {
    changeAttributeFilterSelection,
    selectFilterContextAttributeFilterByDisplayForm,
    useDashboardSelector,
    useDispatchDashboardCommand,
    resetDashboard,
} from '@gooddata/sdk-ui-dashboard';
import { attributeDisplayFormRef, newAttribute } from '@gooddata/sdk-model';
import hierarchyAtom from 'core/framework/recoil/atoms/hierarchy-atom';
import venueIdsAtom from 'core/framework/recoil/atoms/venue-ids-atom';
import impersonationAtom from 'core/framework/recoil/atoms/impersonation-atom';
import LevelService from 'core/framework/services/level-service';

const R = { values, mergeLeft, pick };

/**
 * Component to render 'VenueFilterWidget'. If you create custom widget instance and also pass extra data,
 * then that data will be available in the `widget` prop.
 *
 * @returns {null}
 */
const VenueFilterWidget = () => {
    const [filterUpdated, setFilterUpdated] = useState(false);
    const impersonation = useRecoilValue(impersonationAtom);
    const hierarchy = useRecoilValue(hierarchyAtom);
    const venueIds = useRecoilValue(venueIdsAtom);
    const venueFilter = newAttribute('wifi_gooddata_venue.id');

    useEffect(() => {
        setFilterUpdated(false);
    }, [impersonation]);

    // Select the attribute filter's local identifier from the filter's display form.
    const venueFilterSelector = useDashboardSelector(
        selectFilterContextAttributeFilterByDisplayForm(attributeDisplayFormRef(venueFilter)),
    )?.attributeFilter.localIdentifier;

    const changeAttributeFilterSelectionCmd = useDispatchDashboardCommand(changeAttributeFilterSelection);
    const resetDashboardCmd = useDispatchDashboardCommand(resetDashboard);

    if (!venueFilterSelector || filterUpdated) {
        return null;
    }

    const isScopeSelected = impersonation.selectedScope.uniqid !== null;
    const selectedScope = isScopeSelected ? impersonation.selectedScope : impersonation.userScope;
    const { type, uniqid } = selectedScope;

    if (type === 'company') {
        resetDashboardCmd();
        setFilterUpdated(true);

        return null;
    }

    const venueUniqids = isScopeSelected ? LevelService.getVenues([hierarchy], uniqid) : null;

    const ids = isScopeSelected
        ? R.values(R.pick(venueUniqids, venueIds))
        : R.values(venueIds); // Use full hierarchy

    setTimeout(() => {
        changeAttributeFilterSelectionCmd(
            venueFilterSelector,
            { values: ids.map(String) },
            'IN',
        );

        setFilterUpdated(true);
    }, 0);

    return null;
};

export default VenueFilterWidget;
