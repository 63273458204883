import { selector } from 'recoil';
import translationsState from 'core/framework/recoil/translations/atom';

const appsMenuTranslations = selector({
    key: 'appsMenuTranslations',
    get: ({ get }) => {
        const translations = get(translationsState);
        return translations?.portalHeader?.topbar?.appsMenu;
    },
});

export default appsMenuTranslations;
