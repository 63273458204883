/* eslint-disable no-unused-vars */
import Request from 'core/utils/request';

const NavigationService = {
    getAuthServerBaseUrl() {
        return `${window.location.origin}/services/auth`;
    },

    getAuthServerUrl(path) {
        return this.getAuthServerBaseUrl() + path;
    },

    getCustomerApps(successCallback, errorCallback) {
        Request.get(this.getAuthServerUrl('/api/applications'))
            .then(response => successCallback(response))
            .catch(error => errorCallback(error));
    },
};

export default NavigationService;
