/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React from 'react';
import { ICONS } from 'metroplex';

export const IconComponents = {
    Dashboards: () => (
        <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="8.80981" y="0.631622" width="3.19018" height="13.3684" rx="1.59509" fill="#4575DC" />
            <rect x="4.6322" y="5.72072" width="3.19018" height="8.27927" rx="1.59509" fill="#4575DC" />
            <rect x="0.45459" y="10.8098" width="3.19018" height="3.19018" rx="1.59509" fill="#4575DC" />
        </svg>
    ),
    Help: () => (
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.685 13.685C2.99287 13.685 0 10.6921 0 7C0 3.30787 2.99287 0.314999 6.685 0.314999C10.3771 0.314999 13.37 3.30787 13.37 7C13.37 10.6921 10.3771 13.685 6.685 13.685ZM3.95752 5.15093V5.21911C3.95752 5.30776 3.99274 5.39278 4.05542 5.45546C4.1181 5.51815 4.20312 5.55336 4.29177 5.55336H4.95826C5.00207 5.55336 5.04545 5.54474 5.08592 5.52797C5.12639 5.51121 5.16317 5.48664 5.19414 5.45566C5.22512 5.42469 5.24969 5.38791 5.26645 5.34744C5.28322 5.30697 5.29185 5.26359 5.29185 5.21978C5.29185 4.35742 5.95901 3.89682 6.85614 3.89682C7.73053 3.89682 8.30544 4.35742 8.30544 5.01322C8.30544 5.63358 7.98323 5.92171 7.17835 6.29005L6.94839 6.393C6.26986 6.69249 6.0165 7.14038 6.0165 7.9232V8.00275C6.0165 8.0914 6.05172 8.17642 6.1144 8.2391C6.17708 8.30178 6.2621 8.337 6.35075 8.337H7.01724C7.06105 8.337 7.10443 8.32837 7.1449 8.31161C7.18537 8.29484 7.22215 8.27027 7.25312 8.2393C7.2841 8.20832 7.30867 8.17155 7.32543 8.13107C7.3422 8.0906 7.35083 8.04722 7.35083 8.00342C7.35083 7.65847 7.44308 7.52009 7.7185 7.39374L7.94913 7.29013C8.91512 6.85293 9.63977 6.27802 9.63977 5.02391V4.95506C9.63977 3.63276 8.48995 2.65475 6.85614 2.65475C5.19959 2.65475 3.95752 3.60937 3.95752 5.15093ZM5.68225 10.3365C5.68225 10.9161 6.12279 11.3452 6.67898 11.3452C7.24721 11.3452 7.68775 10.9161 7.68775 10.3365C7.68775 9.75689 7.24721 9.33975 6.67898 9.33975C6.12279 9.33975 5.68225 9.75689 5.68225 10.3365Z" fill="#4575DC" />
        </svg>
    ),
    ExternalLink: () => (
        <svg width="9" height="10" viewBox="0 0 9 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.5">
                <path d="M7.99977 1.44318L0.884018 8.55684" stroke="#616374" strokeWidth="1.58713" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M8.00001 8.39262V1.44318H1.05057" stroke="#616374" strokeWidth="1.58713" strokeLinecap="round" strokeLinejoin="round" />
            </g>
        </svg>
    ),
};

export default {
    ...ICONS,
    dropdownCaret: {
        title: 'dropdown caret',
        viewBox: '0 0 10 6',
        path: 'M5.70715 5.29289C5.31663 5.68342 4.68346 5.68342 4.29294 5.29289L0.707153 1.70711C0.0771886 1.07714 0.523355 0 1.41426 0H8.58583C9.47674 0 9.9229 1.07714 9.29294 1.70711L5.70715 5.29289Z',
    },
    helpTopBar: {
        title: 'Support guides',
        viewBox: '0 0 14 14',
        path: 'M7 14C3.134 14 0 10.866 0 7C0 3.134 3.134 0 7 0C10.866 0 14 3.134 14 7C14 10.866 10.866 14 7 14ZM7 12.6C10.0928 12.6 12.6 10.0928 12.6 7C12.6 3.9072 10.0928 1.4 7 1.4C3.9072 1.4 1.4 3.9072 1.4 7C1.4 10.0928 3.9072 12.6 7 12.6ZM6.3 9.1H7.7V10.5H6.3V9.1ZM7.7 7.94857V8.4H6.3V7.35C6.3 6.96339 6.61339 6.65 7 6.65C7.57988 6.65 8.05 6.17988 8.05 5.6C8.05 5.0201 7.57988 4.55 7 4.55C6.49061 4.55 6.06592 4.91276 5.97016 5.39404L4.59712 5.11942C4.82046 3.99643 5.8114 3.15 7 3.15C8.3531 3.15 9.45 4.2469 9.45 5.6C9.45 6.70985 8.71199 7.64736 7.7 7.94857Z',
    },
    signOut: {
        title: 'Sign out',
        viewBox: '0 0 14 14',
        path: 'M0.7 14C0.313404 14 0 13.6866 0 13.3V0.7C0 0.313404 0.313404 0 0.7 0H10.5C10.8866 0 11.2 0.313404 11.2 0.7V2.1C11.2 2.4866 10.8866 2.8 10.5 2.8V2.8C10.1134 2.8 9.8 2.4866 9.8 2.1V1.4H1.4V12.6H9.8V11.9C9.8 11.5134 10.1134 11.2 10.5 11.2V11.2C10.8866 11.2 11.2 11.5134 11.2 11.9V13.3C11.2 13.6866 10.8866 14 10.5 14H0.7ZM10.6123 9.15012C10.285 9.41203 9.8 9.17894 9.8 8.75969V7.7H5.6C5.2134 7.7 4.9 7.3866 4.9 7V7C4.9 6.6134 5.2134 6.3 5.6 6.3H9.8V5.24031C9.8 4.82106 10.285 4.58797 10.6123 4.84988L12.812 6.60957C13.0622 6.80973 13.0622 7.19027 12.812 7.39043L10.6123 9.15012Z',
    },
    impersonationEye: {
        title: 'Impersonation Eye',
        viewBox: '-1.5 -2 20 20',
        path: 'M8.62361 2C6.37936 2 4.5823 2.98571 3.27408 4.15893C1.97419 5.32143 1.10482 6.71429 0.693744 7.67054C0.602085 7.88214 0.602085 8.11786 0.693744 8.32946C1.10482 9.28571 1.97419 10.6786 3.27408 11.8411C4.5823 13.0143 6.37936 14 8.62361 14C10.8679 14 12.6649 13.0143 13.9731 11.8411C15.273 10.6759 16.1424 9.28571 16.5563 8.32946C16.6479 8.11786 16.6479 7.88214 16.5563 7.67054C16.1424 6.71429 15.273 5.32143 13.9731 4.15893C12.6649 2.98571 10.8679 2 8.62361 2ZM4.62396 8C4.62396 6.97702 5.04535 5.99594 5.79543 5.27259C6.54551 4.54923 7.56284 4.14286 8.62361 4.14286C9.68439 4.14286 10.7017 4.54923 11.4518 5.27259C12.2019 5.99594 12.6233 6.97702 12.6233 8C12.6233 9.02298 12.2019 10.0041 11.4518 10.7274C10.7017 11.4508 9.68439 11.8571 8.62361 11.8571C7.56284 11.8571 6.54551 11.4508 5.79543 10.7274C5.04535 10.0041 4.62396 9.02298 4.62396 8ZM8.62361 6.28571C8.62361 7.23125 7.82646 8 6.84599 8C6.64878 8 6.45991 7.96786 6.28215 7.91161C6.12938 7.86339 5.95162 7.95446 5.95718 8.10982C5.96551 8.29464 5.99328 8.47946 6.04606 8.66429C6.42658 10.0357 7.89034 10.85 9.31244 10.483C10.7345 10.1161 11.5789 8.70446 11.1984 7.33304C10.8901 6.22143 9.87073 5.47411 8.73749 5.42857C8.57639 5.42321 8.48196 5.59196 8.53195 5.74196C8.59028 5.91339 8.62361 6.09554 8.62361 6.28571Z',
    },
    mapPinFill: {
        title: 'map pin icon',
        viewBox: '0 0 24 24',
        path: 'm18.364 17.364-6.364 6.364-6.364-6.364c-5.67-5.6701-1.6537-15.363 6.364-15.363 8.0177 0 12.034 9.6934 6.364 15.363zm-6.364-2.364c5.3333 0 5.3333-8 0-8s-5.3333 8 0 8z',
    },
    globe: {
        title: 'globe icon',
        viewBox: '0 0 24 24',
        path: 'M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM9.71002 19.6674C8.74743 17.6259 8.15732 15.3742 8.02731 13H4.06189C4.458 16.1765 6.71639 18.7747 9.71002 19.6674ZM10.0307 13C10.1811 15.4388 10.8778 17.7297 12 19.752C13.1222 17.7297 13.8189 15.4388 13.9693 13H10.0307ZM19.9381 13H15.9727C15.8427 15.3742 15.2526 17.6259 14.29 19.6674C17.2836 18.7747 19.542 16.1765 19.9381 13ZM4.06189 11H8.02731C8.15732 8.62577 8.74743 6.37407 9.71002 4.33256C6.71639 5.22533 4.458 7.8235 4.06189 11ZM10.0307 11H13.9693C13.8189 8.56122 13.1222 6.27025 12 4.24799C10.8778 6.27025 10.1811 8.56122 10.0307 11ZM14.29 4.33256C15.2526 6.37407 15.8427 8.62577 15.9727 11H19.9381C19.542 7.8235 17.2836 5.22533 14.29 4.33256Z',
    },
    microSurvey: {
        title: 'micro survey icon',
        viewBox: '0 0 24 24',
        path: 'M4.857,2C3.281,2 2,3.281 2,4.857L2,19.143C2,20.719 3.281,22 4.857,22L19.143,22C20.719,22 22,20.719 22,19.143L22,4.857C22,3.281 20.719,2 19.143,2L4.857,2ZM7.714,10.571C8.504,10.571 9.143,11.21 9.143,12L9.143,16.286C9.143,17.076 8.504,17.714 7.714,17.714C6.924,17.714 6.286,17.076 6.286,16.286L6.286,12C6.286,11.21 6.924,10.571 7.714,10.571ZM10.571,7.714C10.571,6.924 11.21,6.286 12,6.286C12.79,6.286 13.429,6.924 13.429,7.714L13.429,16.286C13.429,17.076 12.79,17.714 12,17.714C11.21,17.714 10.571,17.076 10.571,16.286L10.571,7.714ZM16.286,13.429C17.076,13.429 17.714,14.067 17.714,14.857L17.714,16.286C17.714,17.076 17.076,17.714 16.286,17.714C15.496,17.714 14.857,17.076 14.857,16.286L14.857,14.857C14.857,14.067 15.496,13.429 16.286,13.429Z',
    },
    apps: {
        title: 'apps icon',
        viewBox: '0 0 24 24',
        path: 'M7 11.5C4.51472 11.5 2.5 9.48528 2.5 7C2.5 4.51472 4.51472 2.5 7 2.5C9.48528 2.5 11.5 4.51472 11.5 7C11.5 9.48528 9.48528 11.5 7 11.5ZM7 21.5C4.51472 21.5 2.5 19.4853 2.5 17C2.5 14.5147 4.51472 12.5 7 12.5C9.48528 12.5 11.5 14.5147 11.5 17C11.5 19.4853 9.48528 21.5 7 21.5ZM17 11.5C14.5147 11.5 12.5 9.48528 12.5 7C12.5 4.51472 14.5147 2.5 17 2.5C19.4853 2.5 21.5 4.51472 21.5 7C21.5 9.48528 19.4853 11.5 17 11.5ZM17 21.5C14.5147 21.5 12.5 19.4853 12.5 17C12.5 14.5147 14.5147 12.5 17 12.5C19.4853 12.5 21.5 14.5147 21.5 17C21.5 19.4853 19.4853 21.5 17 21.5ZM7 9.5C8.38071 9.5 9.5 8.38071 9.5 7C9.5 5.61929 8.38071 4.5 7 4.5C5.61929 4.5 4.5 5.61929 4.5 7C4.5 8.38071 5.61929 9.5 7 9.5ZM7 19.5C8.38071 19.5 9.5 18.3807 9.5 17C9.5 15.6193 8.38071 14.5 7 14.5C5.61929 14.5 4.5 15.6193 4.5 17C4.5 18.3807 5.61929 19.5 7 19.5ZM17 9.5C18.3807 9.5 19.5 8.38071 19.5 7C19.5 5.61929 18.3807 4.5 17 4.5C15.6193 4.5 14.5 5.61929 14.5 7C14.5 8.38071 15.6193 9.5 17 9.5ZM17 19.5C18.3807 19.5 19.5 18.3807 19.5 17C19.5 15.6193 18.3807 14.5 17 14.5C15.6193 14.5 14.5 15.6193 14.5 17C14.5 18.3807 15.6193 19.5 17 19.5Z',
    },
    user: {
        title: 'user icon',
        viewBox: '0 0 24 24',
        path: 'M4 22C4 17.5817 7.58172 14 12 14C16.4183 14 20 17.5817 20 22H18C18 18.6863 15.3137 16 12 16C8.68629 16 6 18.6863 6 22H4ZM12 13C8.685 13 6 10.315 6 7C6 3.685 8.685 1 12 1C15.315 1 18 3.685 18 7C18 10.315 15.315 13 12 13ZM12 11C14.21 11 16 9.21 16 7C16 4.79 14.21 3 12 3C9.79 3 8 4.79 8 7C8 9.21 9.79 11 12 11Z',
    },
    wifi: {
        title: 'wifi icon',
        viewBox: '0 0 18 13',
        path: 'M9 12.75C8.475 12.75 8.03125 12.5688 7.66875 12.2063C7.30625 11.8438 7.125 11.4 7.125 10.875C7.125 10.35 7.30625 9.90625 7.66875 9.54375C8.03125 9.18125 8.475 9 9 9C9.525 9 9.96875 9.18125 10.3313 9.54375C10.6938 9.90625 10.875 10.35 10.875 10.875C10.875 11.4 10.6938 11.8438 10.3313 12.2063C9.96875 12.5688 9.525 12.75 9 12.75ZM4.7625 8.5125L3.1875 6.9C3.925 6.1625 4.79063 5.57812 5.78438 5.14688C6.77813 4.71563 7.85 4.5 9 4.5C10.15 4.5 11.2219 4.71875 12.2156 5.15625C13.2094 5.59375 14.075 6.1875 14.8125 6.9375L13.2375 8.5125C12.6875 7.9625 12.05 7.53125 11.325 7.21875C10.6 6.90625 9.825 6.75 9 6.75C8.175 6.75 7.4 6.90625 6.675 7.21875C5.95 7.53125 5.3125 7.9625 4.7625 8.5125ZM1.575 5.325L0 3.75C1.15 2.575 2.49375 1.65625 4.03125 0.99375C5.56875 0.33125 7.225 0 9 0C10.775 0 12.4313 0.33125 13.9688 0.99375C15.5063 1.65625 16.85 2.575 18 3.75L16.425 5.325C15.4625 4.3625 14.3469 3.60938 13.0781 3.06563C11.8094 2.52188 10.45 2.25 9 2.25C7.55 2.25 6.19063 2.52188 4.92188 3.06563C3.65313 3.60938 2.5375 4.3625 1.575 5.325Z',
    },
    help: {
        title: 'help icon',
        viewBox: '0 0 24 24',
        path: 'M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM11 15H13V17H11V15ZM13 13.3551V14H11V12.5C11 11.9477 11.4477 11.5 12 11.5C12.8284 11.5 13.5 10.8284 13.5 10C13.5 9.17157 12.8284 8.5 12 8.5C11.2723 8.5 10.6656 9.01823 10.5288 9.70577L8.56731 9.31346C8.88637 7.70919 10.302 6.5 12 6.5C13.933 6.5 15.5 8.067 15.5 10C15.5 11.5855 14.4457 12.9248 13 13.3551ZM12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM11 15H13V17H11V15ZM13 13.3551V14H11V12.5C11 11.9477 11.4477 11.5 12 11.5C12.8284 11.5 13.5 10.8284 13.5 10C13.5 9.17157 12.8284 8.5 12 8.5C11.2723 8.5 10.6656 9.01823 10.5288 9.70577L8.56731 9.31346C8.88637 7.70919 10.302 6.5 12 6.5C13.933 6.5 15.5 8.067 15.5 10C15.5 11.5855 14.4457 12.9248 13 13.3551Z',
    },
    profile: {
        title: 'profile icon',
        viewBox: '0 0 24 24',
        path: 'M17.879,17.609C17.133,15.785 15.34,14.5 13.25,14.5L10.75,14.5C8.66,14.5 6.867,15.785 6.121,17.609C4.73,16.148 3.875,14.176 3.875,12C3.875,7.512 7.512,3.875 12,3.875C16.488,3.875 20.125,7.512 20.125,12C20.125,14.176 19.27,16.148 17.879,17.609ZM16.313,18.887C15.063,19.672 13.586,20.125 12,20.125C10.414,20.125 8.938,19.672 7.684,18.887C7.969,17.453 9.234,16.375 10.75,16.375L13.25,16.375C14.766,16.375 16.031,17.453 16.316,18.887L16.313,18.887ZM12,22C14.652,22 17.196,20.946 19.071,19.071C20.946,17.196 22,14.652 22,12C22,9.348 20.946,6.804 19.071,4.929C17.196,3.054 14.652,2 12,2C9.348,2 6.804,3.054 4.929,4.929C3.054,6.804 2,9.348 2,12C2,14.652 3.054,17.196 4.929,19.071C6.804,20.946 9.348,22 12,22ZM12,11.375C11.586,11.375 11.188,11.21 10.895,10.917C10.602,10.624 10.438,10.227 10.438,9.812C10.438,9.398 10.602,9.001 10.895,8.708C11.188,8.415 11.586,8.25 12,8.25C12.414,8.25 12.812,8.415 13.105,8.708C13.398,9.001 13.563,9.398 13.563,9.812C13.563,10.227 13.398,10.624 13.105,10.917C12.812,11.21 12.414,11.375 12,11.375ZM8.563,9.812C8.563,10.724 8.925,11.599 9.569,12.243C10.214,12.888 11.088,13.25 12,13.25C12.912,13.25 13.786,12.888 14.431,12.243C15.075,11.599 15.438,10.724 15.438,9.812C15.438,8.901 15.075,8.026 14.431,7.382C13.786,6.737 12.912,6.375 12,6.375C11.088,6.375 10.214,6.737 9.569,7.382C8.925,8.026 8.563,8.901 8.563,9.812Z',
    },
    customer: {
        title: 'customer icon',
        viewBox: '0 0 32 32',
        path: 'M1,1v27.5h10.3c0-0.1,0-0.1,0.1-0.2c0-0.1,0-0.2,0.1-0.2c0-0.1,0-0.2,0.1-0.2c0-0.1,0.1-0.3,0.1-0.4c0-0.1,0.1-0.3,0.1-0.4c0.1-0.2,0.1-0.4,0.2-0.5c0-0.1,0.1-0.1,0.1-0.2c0-0.1,0.1-0.2,0.1-0.3c0,0,0,0,0,0H3.5V3.5h18.8v6.3c0.9,0,1.7,0.1,2.5,0.4V1H1zM6,6v2.5h5V6H6z M14.8,6v2.5h5V6H14.8z M6,11v2.5h5V11H6z M14.8,11v2.5h0.3c0.7-1,1.6-1.9,2.7-2.5H14.8z M22.3,12.3c-3.4,0-6.3,2.8-6.3,6.3c0,1.9,0.9,3.6,2.2,4.7c-2.8,1.5-4.7,4.4-4.7,7.8H16c0-3.5,2.8-6.3,6.3-6.3s6.3,2.8,6.3,6.3H31c0-3.4-1.9-6.3-4.7-7.8c1.3-1.2,2.2-2.8,2.2-4.7C28.5,15.1,25.7,12.3,22.3,12.3z M22.3,14.8c2.1,0,3.8,1.7,3.8,3.8s-1.7,3.8-3.8,3.8s-3.8-1.7-3.8-3.8S20.2,14.8,22.3,14.8z M6,16v2.5h5V16H6z M6,21v2.5h5V21H6z',
    },
    oneDirection: {
        title: 'rightArrow',
        viewBox: '0 0 12 10',
        path: 'M9.1287 4.26238L6.39482 1.57346C6.09853 1.28205 6.09853 0.804375 6.39482 0.512958C6.68424 0.228294 7.14846 0.228293 7.43788 0.512956L11.2751 4.28709C11.6735 4.67892 11.6735 5.32117 11.2751 5.71299L7.43788 9.48706C7.14846 9.77171 6.68424 9.77171 6.39482 9.48705C6.09853 9.19564 6.09854 8.71796 6.39483 8.42655L9.1287 5.73771H0.737664C0.330264 5.73771 0 5.40744 0 5.00004C0 4.59264 0.330263 4.26238 0.737663 4.26238H9.1287Z',
    },
};
